/**
 * /* eslint-disable no-unused-vars
 *
 * @format
 */

export const deleteColor = "#F53F3F";
export const greyColor = "#C9CDD4";

export const colors = [
  "#5BBEF0",
  "#76BD93",
  "#E5B682",
  "#8AE1B9",
  "#46C2CB",
  "#749AE4",
  "#FF8787",
  "#F8C4B4",
  "#A5CCDA",
  "#8A7FDE",
  "#EEB76B",
  "#BCBF56",
  "#FFDCA9",
  "#FAAB78",
  "#EBC860",
  "#B8D589",
  "#98A8F8",
  "#D989B5",
  "#DE9A9A",
  "#BA94D1",
  "#BCCEF8",
  "#7CCEB0",
  "#C8DBBE",
  "#D0B8A8",
];

export const echartsColors = [
  "#6DA4FA",
  "#68DDB5",
  "#7084A3",
  "#F9C719",
  "#F17B62",
  "#7DD2F0",
  "#A677C6",
  "#FFA956",
  "#1DA3A4",
  "#FFADCE",
];
