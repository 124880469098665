import * as creator from "pages/Verification/apiHelper";
import * as APIDefinitions from "constants/APITypes";

let request: any;

export const init = (token: string | null, apiBase: any, apiHelper: any) => {
  const { requestCreator, requestOptionsCreator } = apiHelper || creator;
  request = requestCreator(apiBase, requestOptionsCreator(token));
};

export const verifyCheck = (body: APIDefinitions.VerifyCheckProps) =>
  request({
    url: `/oauth/v1/verification/check`,
    body,
    method: "POST",
  });

export const resentVerifyCode = (body: APIDefinitions.ResendVerifyCodeProps) =>
  request({
    url: "/oauth/v1/verification/resend",
    body,
    method: "POST",
  });

export const verifyCode = (body: APIDefinitions.VerifyCodeProps) =>
  request({
    url: `/oauth/v1/verification/verify`,
    body,
    method: "POST",
  });

export const getQrCode = (body: APIDefinitions.GetQRCodeProps) =>
  request({
    url: `/oauth/v1/verification/app/get_qr_code`,
    body,
    method: "POST",
  });

export const verifyQRCode = (body: APIDefinitions.VerifyQRCodeProps) =>
  request({
    url: `/oauth/v1/verification/app/verify`,
    body,
    method: "POST",
  });

export const checkQRCode = (body: APIDefinitions.CheckQRCodeProps) =>
  request({
    url: `/oauth/v1/verification/app/check`,
    body,
    method: "POST",
  });

export const getDevice = (body: APIDefinitions.GetDeviceProps) =>
  request({
    url: `/oauth/v1/device/app/get/device`,
    body,
    method: "POST",
  });

export const SetDevicePrimary = (body: APIDefinitions.SetDevicePrimaryProps) =>
  request({
    url: `/oauth/v1/device/app/set/primary`,
    body,
    method: "POST",
  });
