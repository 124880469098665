import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import mfaReducer from "store/slices/mfaSlice";
import usersSlice from "store/slices/usersSlice";
import uiReducer from "store/slices/uiSlice";
const persistConfig = {
  key: "root",
  storage,
};

const reducers = {
  mfa: mfaReducer,
  ui: uiReducer,
  usersCollection: usersSlice,
};

const rootReducer = combineReducers(reducers);
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
