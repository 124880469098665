/* eslint-disable no-throw-literal */
/**
 * /* eslint-disable no-unused-vars
 *
 * @format
 */

import { LooseStaticObject } from "constants/interface";
import {
  keysToCamel,
  keysToSnack,
  camelToSnack,
  objToQuery,
} from "utils/stringUtil";

export const requestOptionsCreator =
  (token: string, tenantId: string) =>
  (method: string, body: any, retoken: string, pub: string) => {
    const headers: LooseStaticObject = {
      "content-type": "application/json",
      "X-HPBP-Tenant-ID": tenantId || "hpbp-dev",
    };
    if (!pub) {
      headers.authorization = `Bearer ${token}`;
    }
    return {
      method,
      body: body ? JSON.stringify(body) : null,
      headers,
    };
  };

export const requestCreator =
  (apiBase: string, requestOptions: any) =>
  async ({
    url,
    queryParams,
    body,
    method,
  }: {
    url: string;
    queryParams: any;
    body: any;
    method: string;
  }) => {
    // const result = await tokenManager.getAndLockToken();

    const options = requestOptions(
      method || "GET",
      keysToSnack(body)
      // result.token
    );

    const query =
      queryParams &&
      Object.keys(queryParams).filter((k) => queryParams[k] !== null).length > 0
        ? `?${objToQuery(camelToSnack, queryParams)}`
        : "";
    try {
      const resp = await fetch(
        url.startsWith("http") ? `${url}${query}` : `${apiBase}${url}${query}`,
        options
      );
      let data: LooseStaticObject = {};
      try {
        data.result = keysToCamel(await resp.json());
      } catch (e) {
        // just ignore this error
      }

      if (resp.status >= 400) {
        throw { ...data.result, status: resp.status };
      }
      return data.result;
    } catch (error: any) {
      console.log(error);
      if (error.status >= 400) {
        if (error.status === 401) {
          // tokenManager.logout()
          localStorage.clear();
          window.location.href = "/unauthorized";
        } else {
          // eslint-disable-next-line no-throw-literal
          // throw { ...data.result, status: error.status };
        }
      }

      if (!error.status && error.message === "Failed to fetch") {
        // tokenManager.logout()
      }

      if (error.status === 401) {
        // const tokenResult = await tokenManager.focusRefreshToken()
        // newToken = tokenResult.result.newToken.token
      }
      throw keysToCamel(error);
    } finally {
    }
  };
