import * as api from "api";
import Root from "pages";
import { ConfigProvider } from "antd";
import * as verifyApi from "pages/Verification/api";
import React, { FC, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { StyleSheetManager } from "styled-components";
import { apiBase, verifyApiBase, verifyTenantId } from "utils/constants";
import { createUser, selectUser } from "store/slices/usersSlice";
import ErrorBoundary from "components/shared/elements/ErrorBoundary";

interface AppProps {
  apiHelper?: any;
}
const App: FC<AppProps> = (props) => {
  const dispatch = useAppDispatch();
  const {
    mfa: { userId, token },
  } = useAppSelector((state) => state);

  useEffect(() => {
    dispatch(createUser(userId));
  }, []);

  const user = useAppSelector((state) => selectUser(state, userId));
  const { accessToken } = user;

  verifyApi.init(accessToken || token, verifyApiBase, props.apiHelper);
  api.init(accessToken || token, apiBase, props.apiHelper, verifyTenantId);
  const queryClient = new QueryClient();
  return (
    <ErrorBoundary>
      <ConfigProvider
        theme={{
          token: { borderRadius: 4 },
          components: {
            Switch: {
              colorPrimary: "#00c259",
              colorPrimaryHover: "#00c259",
            },
          },
        }}
      >
        <StyleSheetManager disableCSSOMInjection>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <Root />
            </BrowserRouter>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </QueryClientProvider>
        </StyleSheetManager>
      </ConfigProvider>
    </ErrorBoundary>
  );
};

export default App;
