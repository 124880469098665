import ToastNode from "components/shared/elements/Toast";
import React, { FC, useEffect, lazy, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { changeToken, changeUserId } from "store/slices/mfaSlice";
import { selectUser, createUser, patchUser } from "store/slices/usersSlice";

const Management = lazy(() => import("pages/Management"));
const UnAuthorized = lazy(() => import("pages/UnAuthorized"));
const Verification = lazy(() => import("pages/Verification"));
const ErrorPage = lazy(() => import("pages/Error"));
const NoSupportDevice = lazy(() => import("pages/NoSupportDevice"));

const App: FC = () => {
  const dispatch = useAppDispatch();
  const [sessionId, setSessionId] = useState("");
  const [codeAddress, setCodeAddress] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emailTemplateId, setEmailTemplateId] = useState("");
  const { mfa } = useAppSelector((state) => state);
  const location = useLocation();
  const navigate = useNavigate();
  const user = useAppSelector((state) => selectUser(state, mfa.userId));
  const { fingerprintId } = user;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const urlToken = searchParams.get("token");
    const urlUserId = searchParams.get("user_id");
    const urlEmailTemplateId = searchParams.get("email_template_id");
    const urlSessionId =
      searchParams.get("mfa_session_id") || searchParams.get("session_id");
    const urlCodeAddress = searchParams.get("email");
    const urlErrorMessage = searchParams.get("error_message");
    if (!!urlSessionId) {
      setSessionId(urlSessionId);
    }
    if (!!urlEmailTemplateId) {
      setEmailTemplateId(urlEmailTemplateId);
    }
    if (!!urlCodeAddress) {
      setCodeAddress(urlCodeAddress);
    }
    if (!!urlErrorMessage) {
      setErrorMessage(urlErrorMessage);
    }
    if (urlToken && urlToken !== mfa.token) {
      dispatch(changeToken(urlToken));
    }
    if (urlUserId && urlUserId !== mfa.userId) {
      dispatch(changeUserId(urlUserId));
      dispatch(createUser(urlUserId));
    }
    if (
      urlUserId &&
      urlToken &&
      (urlUserId !== mfa.userId || urlToken !== mfa.token)
    ) {
      dispatch(
        patchUser({
          userId: urlUserId,
          patchData: {
            ...user,
            userId: urlUserId,
            accessToken: urlToken,
          },
        })
      );
    }

    if (urlToken) {
      navigate(`/device`);
    } else {
      if (errorMessage || urlErrorMessage) {
        navigate(`/error?error_message=${errorMessage}`);
      } else if (
        !location.pathname.startsWith("/oauth/v1/error") &&
        !(sessionId || urlSessionId) &&
        !fingerprintId
      ) {
        navigate(`/unauthorized`);
      }
    }
  }, [
    dispatch,
    fingerprintId,
    location,
    mfa,
    errorMessage,
    navigate,
    sessionId,
    user,
  ]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate replace to="/verification" />} />
        <Route
          path="/verification"
          element={
            <Verification
              sessionId={sessionId}
              codeAddress={codeAddress}
              emailTemplateId={emailTemplateId}
            />
          }
        />
        <Route
          path="/device"
          element={<NoSupportDevice sessionId={sessionId} />}
        />
        <Route path="/managements/*" element={<Management />} />
        <Route path="/unauthorized" element={<UnAuthorized />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/*" element={<Navigate replace to="/unauthorized" />} />
      </Routes>
      <ToastNode />
    </>
  );
};

export default App;
