import * as creator from "utils/apiHelper";
import * as APIDefinitions from "constants/APITypes";
import * as queryDefines from "constants/queryDefines";

let request: any;

export const init = (
  token: string | null,
  apiBase: any,
  apiHelper: any,
  tenantId: any
) => {
  const { requestCreator, requestOptionsCreator } = apiHelper || creator;
  request = requestCreator(apiBase, requestOptionsCreator(token, tenantId));
};

export const fetchDevices = (queryParams: queryDefines.DevicesQueryParam) =>
  request({
    url: "/v1/mfa/device",
    queryParams,
  });

export const sendCode = (body: APIDefinitions.AddDeviceProps) =>
  request({
    url: "/v1/mfa/device/code",
    body,
    method: "POST",
  });

export const addDevice = (body: APIDefinitions.AddDeviceProps) =>
  request({
    url: "/v1/mfa/device",
    body,
    method: "POST",
  });

export const serPrimaryDevice = (body: APIDefinitions.SetPrimaryDeviceProps) =>
  request({
    url: "/v1/mfa/device/primary",
    body,
    method: "POST",
  });
export const deleteDevice = (queryParams: APIDefinitions.DeleteDeviceProps) =>
  request({
    url: "/v1/mfa/device",
    queryParams,
    method: "DELETE",
  });
