/** @format */

export const apiBase =
  window.REACT_APP_API_BASE || process.env.REACT_APP_API_BASE;
export const verifyApiBase =
  window.REACT_APP_VERIFY_API_BASE || process.env.REACT_APP_VERIFY_API_BASE;
export const verifyTenantId = process.env.REACT_APP_VERIFY_TENANT_ID;
export const apiOauthBase =
  window.REACT_APP_SHELL_API_BASE || process.env.REACT_APP_SHELL_API_BASE;
export const oauthCallback =
  window.REACT_APP_SHELL_OAUTH_CALLBACK ||
  process.env.REACT_APP_SHELL_OAUTH_CALLBACK;
export const clientId =
  window.REACT_APP_SHELL_CLIENT_ID || process.env.REACT_APP_SHELL_CLIENT_ID;

export const helpEmail =
  window.REACT_APP_SHELL_HELP_EMAIL || process.env.REACT_APP_SHELL_HELP_EMAIL;
