import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

interface UiStateProps {
  sidebarMode: string;
}
const initialState: UiStateProps = {
  sidebarMode: "expanded",
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    changeSidebarMode: (state, action: PayloadAction<string>) => {
      state.sidebarMode = action.payload;
    },
  },
});
export const { changeSidebarMode } = uiSlice.actions;

export const ui = (state: RootState) => state.ui;
export default uiSlice.reducer;
