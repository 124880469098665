import React, { Component, Suspense } from "react";
import NProgress from "components/shared/elements/NProgress";
import { Button, Result } from "antd";
import styled from "styled-components";
import { path } from "ramda";
import { SmileOutlined } from "@ant-design/icons";
import { deleteColor } from "constants/colors";

const StyledErrorMsg = styled.div`
  text-align: center;
  color: ${deleteColor};
`;

class ErrorBoundary extends Component<
  { setStart?: any; children: any },
  { hasError?: boolean; errorMsg: string | undefined; retryCount: number }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, errorMsg: "", retryCount: 0 };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error);
    console.log(errorInfo);
    this.setState((state) => {
      return { errorMsg: error };
    });
    // some code here...
  }

  reloadJs() {
    this.setState((state) => {
      return { hasError: false };
    });
  }

  render() {
    const { hasError, errorMsg }: any = this.state;
    const {
      setStart,
      children,
      fallback = (
        <>
          <Result
            icon={<SmileOutlined />}
            title="Something went wrong!"
            extra={
              <Button type="primary" onClick={this.reloadJs.bind(this)}>
                reload
              </Button>
            }
          />
          <StyledErrorMsg>{path(["message"], errorMsg)}</StyledErrorMsg>
        </>
      ),
    }: any = this.props;

    return hasError ? (
      fallback
    ) : (
      <Suspense fallback={<NProgress setStart={setStart} />}>
        {children}
      </Suspense>
    );
  }
}

export default ErrorBoundary;
