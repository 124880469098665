import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

export interface MfaStateProps {
  token: string;
  userId: string;
  sessionId: string;
  codeAddress: string | null;
  errorMessage: string | null;
}
const initialState: MfaStateProps = {
  token: "",
  userId: "",
  sessionId: "",
  codeAddress: "",
  errorMessage: "",
};

export const mfaSlice = createSlice({
  name: "mfa",
  initialState,
  reducers: {
    changeToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    changeUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    changeSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload;
    },
    changeCodeAddress: (state, action: PayloadAction<string | null>) => {
      state.codeAddress = action.payload;
    },
    changeErrorMessage: (state, action: PayloadAction<string | null>) => {
      state.errorMessage = action.payload;
    },
    resetMfaState: (state) => {
      return initialState;
    },
  },
});
export const {
  changeToken,
  changeUserId,
  changeSessionId,
  changeCodeAddress,
  changeErrorMessage,
  resetMfaState,
} = mfaSlice.actions;
export const mfa = (state: RootState) => state.mfa;
export default mfaSlice.reducer;
